<template>
    <header>
        <aside>
            <router-link :to="{ name: 'home' }">
                <img
                    src="/thryv-logo.png"
                    alt="Thryv"
                    width="200"
                />
            </router-link>

            <h2 class="page-title">
                <portal-target name="title" />
            </h2>
        </aside>

        <nav v-if="user.uid">
            <DemoWidget v-if="demo.projectId" />

            <portal-target name="nav-actions" multiple />

            <DsFilledButton
                v-if="showVoteButton"
                class="vote-button"
                @click="$router.push({ name: 'hackathon.vote', params: { id: activeHackathon.id } })"
            >
                Vote now!
            </DsFilledButton>

            <form
                class="search-form"
                @submit.prevent="$router.push({ name: 'search', query: { q: searchTerm } })"
            >
                <DsInputField
                    v-model="searchTerm"
                    required
                    placeholder="Search"
                    type="search"
                />

                <DsIconButton name="search" />
            </form>

            <DsDropdown style="--dropdown-height: 400px" :dense="isAdmin" class="user-dropdown">
                <template #default>
                    <UserAvatar
                        class="user-avatar"
                        :user="user"
                    />
                </template>

                <template #menu>
                    <!-- <p>
                        Welcome back,
                        <router-link :to="{ name: 'user', params: { id: user.uid } }">
                            {{ user.name }}!
                        </router-link>
                    </p> -->

                    <ul class="dropdown-menu-list">
                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'ideas.new' })"
                        >
                            Submit idea
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'hall.of.fame' })"
                        >
                            Hall of fame
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'ideas' })"
                        >
                            Browse Ideas
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'user', params: { id: user.uid } })"
                        >
                            Profile
                        </li>

                        <li class="dropdown-menu-item destructive" @click="logout">
                            Log out
                        </li>

                        <li
                            v-if="activeHackathon"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'projects.new' })"
                        >
                            Create project
                        </li>

                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'new.hackathon' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Create hackathon
                        </li>

                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'votes' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Voting results
                        </li>

                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'guests' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Guest list
                        </li>

                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'demo' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Demo
                        </li>

                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'users' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Users
                        </li>

                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'tags' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Manage tags
                        </li>
                    </ul>
                </template>
            </DsDropdown>
        </nav>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DemoWidget from '@/components/DemoWidget';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import UserAvatar from './UserAvatar';

export default {
    components: {
        DemoWidget,
        UserAvatar,
    },

    data() {
        return {
            searchTerm: '',
        };
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            isAdmin: ({ auth }) => auth.user.isAdmin,
            project: ({ projects }) => projects.project,
            hackathon: ({ hackathons }) => hackathons.hackathon,
            demo: ({ hackathons }) => hackathons.demo,
            hackathons: ({ hackathons }) => hackathons.hackathons,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
            getProject: 'projects/getProject',
        }),

        showVoteButton() {
            if (this.$route.name === 'hackathon.vote' || !this.activeHackathon) return false;

            const { startDate, endDate } = this.activeHackathon;

            return moment().isBetween(moment(startDate), moment(endDate));
        },
    },

    methods: {
        logout() {
            firebase.auth().signOut().then(() => {
                this.$store.commit('auth/SET_USER', { email: '' });
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.$router.push({ name: 'login' });
            }).catch(() => {});
        },
    },

};
</script>

<style lang="scss" scoped>
    .user-avatar {
        cursor: pointer;
    }

    header {
        z-index: 1;
        padding: $spacing-200 $spacing-200 $spacing-200 0;
        align-items: center;
        display: flex;
        justify-content: space-between;

        --logo-width: #{px-to-rem(87)};
        height: px-to-rem(72);
    }

    aside {
        display: grid;
        grid-template-columns: px-to-rem(87) auto;
        align-items: center;
        gap: $spacing-200;
        padding: 0 $spacing-200;
        justify-content: center;
        align-items: center;
    }

    nav {
        display: flex;
        align-items: center;
    }

    h2 {
        color: $color-ink-700;
        font-size: $font-size-lg;
        font-weight: bold;
    }

    .page-title {
        @media($extra-small) {
            display: none;
        }
    }

    .status-container {
        margin-right: $spacing-100;
    }

    .search-button {
        margin: 0 $spacing-200;
    }

    .vote-button {
        margin-left: $spacing-200;
    }

    .search-form {
        display: flex;
        margin-right: $spacing-100;
        padding-right: $spacing-100;
        gap: $spacing-100;
        --input-margin-bottom: 0;
        border-right: 1px solid $color-gray-400;
    }

    .user-dropdown .open {
        z-index: 4;
    }
</style>
