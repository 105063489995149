<template>
    <div class="user-avatar">
        <DsAvatar
            :size="size"
            :name="user.name"
            :email="user.email"
            :image-url="user.photoUrl"
            :title="user.name"
        />

        <DsIcon
            v-if="isTeamLead"
            name="flag-fill"
            title="Team lead"
            class="team-lead-indicator"
        />

        <div class="winner-badges" :style="{ '--icon-size': badgeSize, 'max-width': size, 'max-height': size, left: `${(size / 2) - (winningProjectsFiltered.length)}px` }">
            <DsIcon
                v-for="(project, i) in winningProjectsFiltered"
                :key="project.id"
                :style="{ left: `${(i) * -10}px` }"
                name="award-fill"
                :title="project.name"
                class="winner-badge"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        isTeamLead: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 40,
        },
    },

    computed: {
        ...mapGetters({
            winningProjectIds: 'hackathons/winningProjectIds',
            getProjectsByUser: 'projects/getProjectsByUser',
        }),

        myProjects() {
            if (!this.user.uid) return [];

            return this.getProjectsByUser(this.user.uid);
        },

        winningProjects() {
            return this.myProjects.filter((project) => this.winningProjectIds.some((projectId) => project.id === projectId));
        },

        winningProjectsFiltered() {
            return this.winningProjects.slice(0, 6); // Limit to 3 badges cause i can't figure out stacking
        },

        badgeSize() {
            if (this.size > 90) {
                return '26px';
            }

            if (this.size <= 40) {
                return '14px';
            }

            return '16px';
        },
    },
};
</script>

<style lang="scss" scoped>
.user-avatar {
    position: relative;

    :deep(.letter) {
        // width: 100%;
    }
}

.team-lead-indicator {
    --icon-color: #{$color-gray-700};
    --icon-size: 14px;
    padding: 2px;
    position: absolute;
    z-index: 1;
    left: -1px;
    top: -4px;
    border: 1px solid $color-gray-500;
    background-color: $color-white;
    border-radius: $border-radius;
}

.winner-badges {
    z-index: 1;
    position: absolute;
    bottom: -2px;
    display: flex;
    --icon-color: #{$color-yellow-800};
}

.winner-badge {
    padding: 2px;
    position: relative;
    border: 1px solid $color-yellow-800;
    background-color: $color-white;
    border-radius: $border-radius;
}
</style>
