import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default {
    LOAD_USERS(context) {
        return loadUsers(context);
    },

    LOAD_GUEST_LIST(context) {
        return loadGuestList(context);
    },

    ADD_GUEST_USER({ dispatch }, payload) {
        const db = firebase.firestore();

        return db.collection('guests').doc().set(payload)
            .then(() => {
                dispatch('LOAD_GUEST_LIST');
            })
            .catch((e) => { throw e; });
    },

    DELETE_GUEST_USER(context, guestUserId) {
        const db = firebase.firestore();

        return db.collection('guests').doc(guestUserId).delete()
            .catch((e) => { throw e; });
    },

    LOAD_USER(context, uid) {
        return loadUser(context, uid);
    },

    CREATE_USER(context, uid) {
        return createUser(context, uid);
    },

    EDIT_USER(context, payload) {
        return editUser(context, payload);
    },
};

const loadUser = ({ commit }, uid) => {
    const db = firebase.firestore();

    return db.collection('users').doc(uid).get()
        .then((doc) => {
            const data = doc.data();

            commit('SET_USER', data);

            return data;
        })
        .catch((e) => { throw e; });
};


const loadUsers = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('users').get()
        .then((querySnapshot) => {
            const data = [];

            querySnapshot.forEach((doc) => {
                const d = doc.data();

                data.push({
                    id: doc.id,
                    ...d,
                });
            });

            data.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;

                return 0;
            });

            commit('SET_USERS', data);

            return data;
        })
        .catch((e) => { throw e; });
};

const loadGuestList = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('guests').get()
        .then((querySnapshot) => {
            const data = [];

            querySnapshot.forEach((doc) => {
                const d = doc.data();

                data.push({
                    id: doc.id,
                    ...d,
                });
            });

            data.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;

                return 0;
            });

            commit('SET_GUESTS', data);

            return data;
        })
        .catch((e) => { throw e; });
};

const editUser = ({ commit }, payload) => {
    const db = firebase.firestore();

    return db.collection('users').doc(payload.uid).set(payload)
        .then(() => {
            commit('SET_USER', payload);
        })
        .catch((e) => { throw e; });
};

const createUser = ({ commit }, payload) => {
    const db = firebase.firestore();

    return db.collection('users').doc(payload.uid).set(payload)
        .then(() => {
            commit('SET_USER', payload);
        })
        .catch((e) => { throw e; });
};
